<template>
  <!--  -->
  <div class="layout">
    <div class="container py-5">
      <div class="container-inner mt-5 mb-0 mb-md-5">
        <div class="row d-flex justify-content-between py-5">
          <div
            class="
              col-12 col-md-8
              offset-md-2
              col-lg-6
              offset-lg-3
              mt-5 mt-lg-0
            "
          >
            <form class="login-form px-4">
              <h2 class="text-center mb-5">{{$t('SIGN_IN')}}</h2>
              <div class="form-group">
                <label>{{$t('EMAIL_ADDRESS')}}</label>
                <input
                  :autofocus="true"
                  v-model="form.email"
                  class="customForm"
                  type="text"
                  name="username"
                  :placeholder="$t('ENTER_EMAIL')"
                />
              </div>
              <div class="form-group">
                <label>{{$t('PASSWORD')}}</label>
                <input
                  @keypress="passKeyPress($event)"
                  v-model="form.password"
                  class="customForm"
                  type="password"
                  name="password"
                  :placeholder="$t('ENTER_PASSWORD')"
                />
              </div>
              <div class="form-group mt-4 pt-2">
                <button @click="login" type="button" class="w-100 button">
                  {{$t('SIGN_IN')}}
                </button>
              </div>
              <div v-if="errors?.length" class="errors-wrapper">
                <p :key="error" v-for="error of errors" class="error-message">
                  {{ error }}
                </p>
              </div>
            </form>
            <div class="d-flex mx-4 login-links flex-wrap justify-content-between">
              <router-link class="d-inline-block me-2" to="/reset-password">{{$t('FORGOT_PASSWORD')}}</router-link>
              <router-link class="d-inline-block ms-2" to="/register"
                >{{$t('NO_ACCOUNT_YET')}}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { defineComponent } from "vue";
import Footer from "../../components/Footer.vue";
import config from '../../config';
import spinner from '../../assets/images/spinner.svg';

export default defineComponent({
  name: "Login",
  data: function () {
    return {
      form: {
        email: "",
        password: "",
      },
      accessToken: '',
      errors: [],
      loading: true,
      spinner: spinner
    };
  },
  components: {
    //   Header,
  },
  mounted() {
    console.log(config);
    this.$store.watch(state => {
        this.accessToken = state.accessToken;
        this.loading = state.globalLoading;
    });
    // this.checkAuth();
  },
  methods: {
      passKeyPress(e) {
        if (e.key === 'Enter') {
          this.login();
        }
      },
      login() {
        this.errors = [];
          fetch(`${config.API}/api/v1/login`, {
                method: 'POST',
                headers: {
                    ...config.defaultHeaders,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email: this.form.email,
                    password: this.form.password
                })
          })
          .then(res => res.json())
          .then(data => {
              console.log('login data: ', data);
              if (data.errors) {
                this.errors = data.errors;
              }
              this.$store.dispatch('setUserData', data.data.user);
              this.$store.dispatch('setAccessToken', data.data.access_token);
              this.$store.dispatch('setLoggedIn', true);
              window.localStorage.setItem('accessToken', data.data.access_token);
              this.$router.push('/');
              fetch(`${config.API}/api/v1/get-user-info`, {
                headers: {
                  ...config.defaultHeaders,
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  Authorization: `Bearer ${data.data.access_token}`
                }
              })
              .then(res => res.json())
              .then(data => console.log(data));
          })
          .catch(error => {
              console.log(error);
          })
      }
  }
});
</script>
